import {FETCH, GET} from '@qb-assets/qb-fetch';

window.addEventListener('storage', e=>e.key === 'QbAuthToken' && (location.pathname.startsWith("/auth") ? location.pathname = "/" : location.reload()));
document.stateInteractive.then(()=>localStorage.setItem('QbAuthToken', document.metas.QbAuthToken) )

class QbAuth extends HTMLElement {
    static get token() {
        return localStorage.getItem('QbAuthToken')
    }

    #shadowRoot

    constructor() {
        super();
        this.#shadowRoot = this.attachShadow({mode: "closed"});
    }

    connectedCallback() {
        const linkElem = document.createElement("link");
        linkElem.setAttribute("rel", "stylesheet");
        linkElem.setAttribute("href", document.head.querySelector('link[href^="/assets/qb_auth"]').href);
        this.#shadowRoot.append(linkElem);
        window.history.replaceState({}, "", "/auth");
        if(this.children.length) {
            const children = new DocumentFragment();
            children.append(...this.children);
            return this.#insert(children);
        }
        const action = this.getAttribute("href") || "/auth/start";
        this.removeAttribute("href");
        if(/!\/auth\//.test(action)) debugger;
        this.#request(action);
    }

    #request(pathname, options = {}) {
        options.headers = { "X-QB-AUTH": "TRUE" }
        return FETCH(pathname, options).result({
            content: f=> this.#insert(f.body),
            redirect: f => {    location = f.location }
        });
    }

    #insert(fragment) {
        this.#shadowRoot.querySelectorAll(":not(link)").forEach(n=>n.remove());
        this.#shadowRoot.append(fragment);
        for(const node of this.#shadowRoot.querySelectorAll("form")) {
            node.addEventListener('submit', e => this.handleEvent_submit(e));
            node.addEventListener('formdata', e => this.handleEvent_formdata(e));
            node.addEventListener('reset', e => this.handleEvent_back(e));
        }
        for(const node of this.#shadowRoot.querySelectorAll('a:not([href="/auth"])')) {
            node.addEventListener('click', e => this.handleEvent_click(e));
        }
        this.#shadowRoot.getElementById('disclose')?.addEventListener("input", e => this.handleEvent_disclose(e));
    }

    handleEvent_disclose(evt) {
        const node = evt.currentTarget;
        if(node.checked) {
            for(const n of this.#shadowRoot.querySelectorAll('[type^="password"]')) {
                n.classList.add('password_revealed');
                n.type = "text";
            }
        }
        else {
            for(const n of this.#shadowRoot.querySelectorAll('.password_revealed')) {
                n.classList.remove('password_revealed');
                n.type = "password";
            }
        }
    }

    handleEvent_click(evt) {
        evt.preventDefault();
        this.#request(evt.currentTarget.href, {method: "get"});
    }

    handleEvent_submit(evt) {
        evt.preventDefault();
        new FormData(evt.currentTarget);
    }

    handleEvent_back(evt) {
        evt.preventDefault();
        location.reload();
    }

    handleEvent_formdata(evt) {
        const form = evt.currentTarget;
        this.#request(form.action, {
            method: form.method,
            body: evt.formData
        });
    }
}

customElements.define("qb-auth", QbAuth)
