const debug = true;


class QbFetchException extends Error {
    #fetch
    #error

    constructor(fetch) {
        super();
        this.#fetch = fetch;

        this.stack = this.stack.split(/\n/).find(l => !/\/qb-fetch\.js/.test(l));
        // this.stack = this.stack.replace(/\n.*/s, "\n")
    }

    get fetch() { return this.#fetch }

    get url() { return this.#fetch.url }

    get method() { return this.#fetch.method }

    get ok() { return this.#fetch.ok }

    get status() { return this.#fetch.status }

    get redirected() { return this.#fetch.redirected }

    get requestObject() {return this.#fetch.requestObject }

    get responseObject() {return this.#fetch.responseObject }

    get statusText() {
        const status = this.status;
        if(status) {
            const rv = [this.status, this.#fetch.statusText, this.errorMessage ].filter(n=>n);
            return this.redirected ? `${rv.join("|")} => ${this.responseObject.url}` : rv;
        }
        else return this.#error?.toString() || "Unknown";
    }

    get message() {
        return [this.method, this.url, this.statusText].join(" ")
    }

    get cause() {
        const rv = {request: this.requestObject};
        if(this.responseObject && Object.keys(this.responseObject).length) {
            rv.response = this.responseObject;
        }
        // if(this.#error) rv.error = this.#error.toString();
        return rv;
    }

    get debug() {
        this.name = "QbFetch";
        console.debug(this, this.cause);
    }

    get log() {
        console.error(this, this.cause);
    }

    set error(value) {
        this.name = this.constructor.name;
        this.#error = value;
    }
    get errorMessage() {
        return this.#error?.toString();
    }
}

class QbFetch {
    #url
    #controller
    #signal
    #timeout
    #timeout_handle

    #request
    #requestBody
    #requestHeaders

    #response
    #responseObject
    #exception

    #debug
    #promise
    #bodyPromise
    #bodyResolve

    #urlAppendSearchParams(param) {
        this.#url.searchParams = new URLSearchParams([...this.#url.searchParams.entries(), ...new URLSearchParams(param).entries()]);
    }

    #parseOptions(...args) {
        const options = {};
        this.#url = new URL(location.origin)
        for(let arg of args) {
            switch(arg?.constructor?.name) {
                case "URL":
                    this.#url = arg;
                    break;
                case "String":
                case "Number":
                    arg = arg.toString();
                    if(/https?:\/\//.test(arg)) this.#url = new URL(arg);
                    else {
                        if(arg.startsWith("/")) this.#url.pathname = "/";
                        const m = arg.match(/^([^?#]*)(\?[^#])?(#.+)?$/);
                        if(!m) continue;
                        if(m[1]) this.#url.pathname = (this.#url.pathname + m[1]).replace(/\/+/g, "/");
                        if(m[2]) this.#urlAppendSearchParams = m[2];
                        if(m[3]) this.#url.hash = m[3];
                    }
                    break;
                default:
                    if(typeof arg === "object") Object.assign(options, arg);
                    break;
            }
        }
        return options;
    }


    constructor(...args) {
        const {debug, timeout, headers, body, method, ...options} = this.#parseOptions(...args);
        this.#url = this.#url.toString();
        if(typeof debug === 'boolean') this.#debug = debug;

        this.#controller = new AbortController();
        this.#signal = this.#controller.signal;
        if(typeof timeout === "number") this.timeout = timeout;

        this.#requestHeaders = new Headers(headers || {});
        if(typeof document !== "undefined" && !this.#requestHeaders.get('X-CSRF-Token')) {
            const csrf_token = document.head.querySelector('meta[name="csrf-token"]')?.content;
            if(csrf_token) this.#requestHeaders.set('X-CSRF-Token', csrf_token);
        }

        this.#requestBody = body;

        this.#request = Object.assign({
                method: (method || "get").toUpperCase(),
                cache: 'no-cache'
            },
            options,
            {
                signal: this.#signal,
                mode: 'cors',
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
        this.#bodyPromise = new Promise(r=>this.#bodyResolve = r);

        this.#exception = new QbFetchException(this);
        this.#promise = this.#fetch().catch(e => {
            if(!(e instanceof QbFetchException)) this.#exception.error = e;
            if(this.#debug) console.error(this.#exception, this.#exception.cause);
            return this;
        });
    }

    async result({content, noContent, request, redirect}={}) {
        await this.#promise;
        if(request) request(this);
        if(redirect && this.location) redirect(this);
        if(await this.#bodyPromise) {
            if(content) content(this);
            else if(noContent) noContent(this);
        }
        return this;
    }

    get url() { return this.#url }

    get ok() { return this.#response?.ok }

    get redirected() { return this.#response?.redirected && this.#response?.url }

    get status() { return this.#response?.status }

    get statusText() { return this.#response?.statusText }

    get method() { return this.#request.method }

    get location() { return this.header("location") }

    header(key) { return this.responseObject.headers?.get(key) }

    get requestObject() {
        const rv = {headers: this.#requestHeaders, ...this.#request}
        if(this.#requestBody) rv.body = this.#requestBody;
        return rv;
    }

    get responseObject() { return this.#responseObject }

    get body() {
        throw "Body is not yet resolved"
    }

    set #responseBody(value) {
        if(this.#debug) this.#exception.debug;
        switch(this.content_type) {
            case 'application/json':
                this.#responseObject.body = value = JSON.parse(value);
                break;
            case 'text/html':
                if(typeof document !== "undefined" && value) {
                    value = Object.assign(document.createElement("template"), {innerHTML: value}).content;
                    this.#responseObject.body = [...value.children];
                    break;
                }
            default:
                this.#responseObject.body = value;
        }
        Reflect.defineProperty(this, 'body', { writable: false, value });
        this.#bodyResolve(value);
    }

    get content_type() {
        return this.#response.headers.get("content-type")?.replace(/;.*$/, '');
    }

    get #options() {
        let body = this.#requestBody;
        const headers = this.#requestHeaders;
        if(headers.get('Content-Type') === 'application/json') body = JSON.stringify(body);
        const options = {body, headers, ...this.#request};
        if(this.#signal) options.signal = this.#signal;
        return options;
    }

    #resolve(value) {
        const method = body => {
            this.#responseBody = body;
            if(!this.#response.ok) throw "Not OK";
            // else this.#promiseReject(this);
        }
        if(value instanceof Promise) return value.then(method);
        else return method(value);
    }

    async #fetch() {
        this.#response = await fetch(this.url, this.#options);
        const response = this.#response.clone();

        this.#responseObject = {};
        if(response.url !== this.#url) this.#responseObject.url = response.url;

        for(const k in response) {
            if(/^(headers|bodyUsed|ok|redirected|status(Text)?|type)$/.test(k)) this.#responseObject[k] = response[k];
        }

        if(/^(application\/json|text\/(html|plain))$/.test(this.content_type)) return this.#resolve(response.text());
        switch(response.body?.constructor?.name) {
            case "ReadableStream":
                const reader = response.body.getReader(),
                    chunks = [];
                while(true) {
                    const {done, value} = await reader.read();
                    chunks.push(value);
                    if(done) return this.#resolve(chunks.join(""));
                }
            case "Blob":
                return this.#resolve(response.blob().then((blob) => URL.createObjectURL(blob)));
            case "String":
                return this.#resolve(response.body);
            default:
                throw new TypeError(`Don't know what to do with ${response.body?.constructor?.name}`);
        }
    }

    get abort() {
        this.#controller.abort();
    }

    set timeout(value) {
        clearTimeout(this.#timeout_handle);
        this.#timeout_handle = setTimeout(() => this.abort, this.#timeout = value);
    }
}

const FETCH = function (url, options = {}) {
    return new QbFetch(url, options, {debug});
}
const GET = function (url, ...options) { return FETCH(url, ...options, {method: "GET"}) }
const POST = function (url, ...options) { return FETCH(url, ...options, {method: "POST"}) }
const PUT = function (url, ...options) { return FETCH(url, ...options, {method: "PUT"}) }
const PATCH = function (url, ...options) { return FETCH(url, ...options, {method: "PATCH"}) }
const HEAD = function (url, ...options) { return FETCH(url, ...options, {method: "HEAD"}) }
const DELETE = function (url, ...options) { return FETCH(url, ...options, {method: "DELETE"}) }

Object.defineProperties(FETCH, {
    GET: {value: GET},
    POST: {value: POST},
    PUT: {value: PUT},
    PATCH: {value: PATCH},
    HEAD: {value: HEAD},
    DELETE: {value: DELETE}
});

export {
    QbFetch,
    FETCH,
    GET,
    POST,
    PUT,
    PATCH,
    HEAD,
    DELETE
}



